/**
 * Per fetchare un content type.
 * 
 * @param {String} nodeName
 * @param {Object} options
 * @param {String[]} options.include Array di stringhe dei campi da includere (deve esistere la relazione)
 * @param {Object[]} options.filters Array di oggetti: {key:"campofiltro", value:"valore"}
 * @returns {Promise}
 */
export function fetchNodes(nodeName, options) {
  let url = new URL(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/${nodeName}`);
  if (options && Array.isArray(options.filter)) {
    options.filters.forEach((filter) => {
      url.searchParams.append("filter[" + filter.key + "]", filter.value);
    });
  }
  if (options && Array.isArray(options.include)) {
    url.searchParams.append("include", options.include.join(","));
  }
  return fetch(url)
    .then((res) => res.json())
    .then((json) => {
      let nodes = json.data;
      if (options.include) {
        let included = json.included;
        nodes = addIncludedFields(nodes, included, options.include);
      }
      return nodes;
    });
}

/**
 * Aggiunge al singolo item i campi dell'array included
 *
 * @param {Array} items
 * @param {Array} included
 * @param {Array} fieldNames
 */
export function addIncludedFields(items, included, fieldNames) {
  return items.map((item) => {
    fieldNames.forEach((fieldName) => {
      if (item.relationships[fieldName].data) {
        if (Array.isArray(item.relationships[fieldName].data)) {
          item[fieldName] = item.relationships[fieldName].data.map(
            (relationshipItem) => {
              return included.find((includedItem) => {
                return includedItem.id === relationshipItem.id;
              });
            }
          );
        } else if (item.relationships[fieldName].data.id) {
          item[fieldName] = included.find((includedItem) => {
            return includedItem.id === item.relationships[fieldName].data.id;
          });
        }
      }
    });
    return item;
  });
}
